import "../styles/globals.scss";
import {Analytics} from "@vercel/analytics/react";
import type {AppProps} from "next/app";
import Head from "next/head";

import {getLogger} from "../logging";

const LOG = getLogger(__filename);

LOG.info(
  `Initializing ${process.env.PACKAGE_NAME} v${process.env.PACKAGE_VERSION} (${process.env.ENV})...`
);

export default function App({Component, pageProps}: AppProps) {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <link rel="shortcut icon" href="/images/icon.png" />
      </Head>
      <Component {...pageProps} />
      <Analytics />
    </>
  );
}
